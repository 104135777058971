import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const CurativeTitle = () => (
  <Layout>
    <SEO title="Curative Searches" />
    <div className="page-content">
      <div className="container">
        <h1 className="display-4 text-orange">Curative Searches</h1>
        <h2>Title curative services include:</h2>
        <div className="row my-4">
          <div className="col-md-6">
            <ul>
              <li>Filing indemnity requests on prior liens.</li>
              <li>
                Filing title claims with originating title insurers for denied
                indemnity requests.
              </li>
              <li>Handling lot line adjustment issues.</li>
              <li>
                Identifying and preparing assignments needed to complete chain.
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <ul>
              <li>
                Identifying delinquent taxes and arranging for payment of
                serious delinquent taxes.
              </li>
              <li>Recording necessary reconveyances.</li>
              <li>Resolving problems regarding improper transfers of title.</li>
              <li>
                Working with sublease holders to ensure leases are current and
                those delinquent are resolved.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default CurativeTitle
